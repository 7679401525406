<script>
import TableNoDataIcon from "@/components/TableNoDataIcon.vue";

export default {
  name: "EmptySlot.vue",
  components: {TableNoDataIcon}
}
</script>

<template>
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <!-- 添加您的图片路径 -->
    <TableNoDataIcon style="width: 64px; height: 40px; margin-top: 20px;"/>
    <!-- 添加提示文本 -->
    <span  class="word-family">{{
        $t('ProductDiagnosis.noData')
      }}</span>
  </div>
</template>

<style scoped>

</style>