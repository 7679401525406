<template>
  <div id="app">
    <div class="sidebar">
      <div class="menu-container">
        <el-menu :default-active="activeIndex" :default-openeds="openeds" class="el-menu-vertical-demo"
                 :collapse="isCollapse" @select="handleSelect" @open="handleOpen" @close="handleClose" unique-opened>
          <!-- 产品升级 -->
          <el-menu-item index="2" @click="navigateTo('/ProductUpgrade')" v-if="hasPermission('productUpgrade')"
                        :class="{ 'active': $route.path === '/ProductUpgrade', 'no-hover': $route.path === '/ProductUpgrade', 'menu-item-multiline': true }">

            <ProductUpgradeIcon class="svg-icon"/>
            <span class="left-align">{{ $t('ProductUpgrade.productUpgrade') }}</span>
          </el-menu-item>
          <!-- 产品诊断 -->
          <el-menu-item index="1" @click="navigateTo('/ProductDiagnosis')" v-if="hasPermission('productDiagnose')"
                        :class="{ 'active': $route.path === '/ProductDiagnosis', 'no-hover': $route.path === '/ProductDiagnosis', 'menu-item-multiline': true }">

            <ProductDiagnosisIcon class="svg-icon"/>

            <span class="custom-title left-align">{{ $t('ProductDiagnosis.productDiagnosis') }}</span>
          </el-menu-item>

          <!-- 历史数据 -->
          <el-sub-menu v-if="hasPermission('historyData')" index="3" class="custom-submenu">
            <template #title>
              <HistorytDataIcon class="svg-icon"/>

              <span class="left-align">{{ $t('HistoryData.diagnosisReport.historyData') }}</span>
            </template>
            <el-menu-item index="3-1" @click="navigateTo('/DiagnosisReport')" v-if="hasPermission('diagnoseReport')"
                          :class="{ 'active': $route.path === '/DiagnosisReport', 'no-hover': $route.path === '/DiagnosisReport', 'menu-item-multiline': true }">

              <span class="left-align">{{ $t('HistoryData.diagnosisReport.diagnosisRecord') }}</span>
            </el-menu-item>
            <el-menu-item index="3-2" @click="navigateTo('/UpgradeRecords')" v-if="hasPermission('upgradeRecord')"
                          :class="{ 'active': $route.path === '/UpgradeRecords', 'no-hover': $route.path === '/UpgradeRecords', 'menu-item-multiline': true }">
              <span class="left-align"> {{ $t('HistoryData.UpdateRecord.updateRecord') }}</span>
            </el-menu-item>
            <el-menu-item index="3-3" @click="navigateTo('/FeedbackPage')" v-if="hasPermission('customerFeedback')"
                          :class="{ 'active': $route.path === '/FeedbackPage', 'no-hover': $route.path === '/FeedbackPage', 'menu-item-multiline': true }">
              <span class="left-align"> {{ $t('HistoryData.CustomerFeedback.customerFeedback') }}</span>
            </el-menu-item>
          </el-sub-menu>

          <!-- 产品管理 -->
          <el-sub-menu v-if="hasPermission('productManage')" index="4"
                       :class="{ 'custom-hover': 'custom-hover', 'menu-item-multiline': true }">
            <template #title>
              <ProductManageIcon class="svg-icon"/>

              <span class="left-align">{{ $t('ProductManage.ModelManage.productManage') }}</span>
            </template>

            <el-menu-item index="4-1" @click="navigateTo('/ModelManagement')" v-if="hasPermission('modelManage')"
                          :class="{ 'active': $route.path === '/ModelManagement', 'no-hover': $route.path === '/ModelManagement', 'menu-item-multiline': true }">
              <span class="left-align">{{ $t('ProductManage.ModelManage.modelManage') }}</span>
            </el-menu-item>
            <el-menu-item index="4-2" @click="navigateTo('/ConfigManagement')" v-if="hasPermission('configMange')"
                          :class="{ 'active': $route.path === '/ConfigManagement', 'no-hover': $route.path === '/ConfigManagement', 'menu-item-multiline': true }">
              <span class="left-align">{{ $t('ProductManage.ConfigManage.configManage') }}</span>
            </el-menu-item>
          </el-sub-menu>

          <!-- 申请售后 -->
          <el-menu-item index="5" @click="navigateTo('/AfterSales')" v-if="hasPermission('applyAfterSale')"
                        :class="{ 'active': $route.path === '/AfterSales', 'no-hover': $route.path === '/AfterSales', 'menu-item-multiline': true }">
            <AfterSalesIcon class="svg-icon"/>

            <span class="left-align">{{ $t('ApplyAfterSales.applyAfterSales') }}</span>
          </el-menu-item>

          <!-- 售后处理 -->
          <el-menu-item index="6" @click="navigateTo('/ServiceCenter')" v-if="hasPermission('dealAfterSale')"
                        :class="{ 'active': $route.path === '/ServiceCenter', 'no-hover': $route.path === '/ServiceCenter', 'menu-item-multiline': true }">

            <ServiceCenterIcon class="svg-icon"/>

            <span class="left-align">{{ $t('ProcessAfterSale.processAfterSale') }}</span>
          </el-menu-item>

          <!-- 用户管理 -->
          <el-sub-menu v-if="hasPermission('userManage')" index="7">
            <template #title>

              <UserManageIcon class="svg-icon"/>

              <span class="left-align"> {{ $t('userManage.userManagement') }}</span>
            </template>
            <el-menu-item index="7-1" @click="navigateTo('/RoleManagement')" v-if="hasPermission('roleManage')"
                          :class="{ 'active': $route.path === '/RoleManagement', 'no-hover': $route.path === '/RoleManagement', 'menu-item-multiline': true }">
              <span class="left-align">  {{ $t('userManage.roleManagement') }} </span>
            </el-menu-item>
            <el-menu-item index="7-2" @click="navigateTo('/OrganizationManagement')"
                          v-if="hasPermission('organization')"
                          :class="{ 'active': $route.path === '/OrganizationManagement', 'no-hover': $route.path === '/OrganizationManagement', 'menu-item-multiline': true }">
              <span class="left-align"> {{ $t('userManage.organizationManagement') }}</span>
            </el-menu-item>
            <el-menu-item index="7-3" @click="navigateTo('/SelfRegistration')" v-if="hasPermission('selfRegister')"
                          :class="{ 'active': $route.path === '/SelfRegistration', 'no-hover': $route.path === '/SelfRegistration', 'menu-item-multiline': true }">
              <span class="left-align"> {{ $t('userManage.selfRegAccountManagement') }}</span>
            </el-menu-item>
          </el-sub-menu>

          <!-- FAQ管理 -->
          <el-menu-item index="8" @click="navigateToQuickDiagnosis('/FAQManagement')" v-if="hasPermission('faq')"
                        :class="{ 'active': $route.path === '/FAQManagement', 'no-hover': $route.path === '/FAQManagement', 'menu-item-multiline': true }">
            <FAQManagementIcon class="svg-icon"/>

            <span class="left-align">{{ $t('quickDiagnosis.quickDiagnosisManagement') }}</span>
          </el-menu-item>

          <el-sub-menu v-if="hasPermission('tools')" index="9">
            <template #title>
              <el-icon>
                <Setting/>
              </el-icon>
              管理工具
            </template>
            <el-menu-item index="9-1" @click="navigateTo('/tools/ws')" v-if="hasPermission('toolsWs')"
                          :class="{ 'active': $route.path === '/tools/ws', 'no-hover': $route.path === '/tools/ws' }">

              websocket测试
            </el-menu-item>
            <el-menu-item index="9-2" @click="navigateTo('/tools/plugin')" v-if="hasPermission('toolsPlugin')"
                          :class="{ 'active': $route.path === '/tools/plugin', 'no-hover': $route.path === '/tools/plugin' }">
              插件管理
            </el-menu-item>
          </el-sub-menu>

        </el-menu>

      </div>
    </div>
  </div>
</template>

<script>
import {
  Setting
} from "@element-plus/icons-vue";
import ProductUpgradeIcon from "@/components/ProductUpgradeIcon.vue";
import ProductDiagnosisIcon from "@/components/ProductDiagnosisIcon.vue";
import HistorytDataIcon from "@/components/HistorytDataIcon.vue";
import ProductManageIcon from "@/components/ProductManageIcon.vue";
import AfterSalesIcon from "@/components/AfterSalesIcon.vue";
import ServiceCenterIcon from "@/components/ServiceCenterIcon.vue";
import UserManageIcon from "@/components/UserManageIcon.vue";
import FAQManagementIcon from "@/components/FAQManagementIcon.vue";
import EventBus from "@/eventBus";

export default {
  components: {
    Setting, ProductUpgradeIcon,
    ProductDiagnosisIcon,
    HistorytDataIcon,
    ProductManageIcon,
    AfterSalesIcon,
    ServiceCenterIcon,
    UserManageIcon,
    FAQManagementIcon,
  },
  props: {
    selectedModule: String,
  },
  data() {
    return {
      isCollapse: false, // 初始时不折叠
      activeIndex: this.$route.path, // 活动索引与当前路由路径匹配
      openeds: JSON.parse(localStorage.getItem("openeds") || "[]"), // 从localStorage中获取已经打开的submenu
      userPermissions: [],
      isAnalyzing: false, // 添加这个变量来跟踪分析状态
      isTesting: false, // 用于跟踪测试是否正在进行
    };
  },
  async created() {
    await this.loadUserPermissions();
    this.initializeMenu();
  },
  mounted() {
    EventBus.on('analysis-status-change', this.handleAnalysisStatusChange);
    EventBus.on('test-status-change', this.handleTestStatusChange);
  },
  beforeUnmount() {
    EventBus.off('analysis-status-change', this.handleAnalysisStatusChange);
    EventBus.off('test-status-change', this.handleTestStatusChange);
  },
  methods: {
    handleTestStatusChange(status) {
      this.isTesting = status;
    },
    handleAnalysisStatusChange(status) {
      this.isAnalyzing = status;
    },
    initializeMenu() {
      // 初始化菜单逻辑，假设已经有权限数据
      this.openeds = []; // 清空已打开的子菜单
    },
    async loadUserPermissions() {
      try {
        const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId'); // 从 localStorage 中获取 userId
        const cacheKey = `userPermissions_${userId}`;
        const cache = localStorage.getItem(cacheKey) || sessionStorage.getItem(cacheKey);
        if (cache) {
          this.userPermissions = JSON.parse(cache);
        } else {
          // 如果没有缓存，可能需要重新获取权限信息
          this.userPermissions = []; // 设置默认权限
        }
      } catch (error) {
        console.error('Error accessing storage:', error);
        this.userPermissions = []; // 设置默认权限
      }
    },
    hasPermission(permission) {
      if (!this.userPermissions || !Array.isArray(this.userPermissions)) {
        console.error('User permissions data is invalid or not loaded');
        return false;
      }

      const hasPermissionRecursive = (permissions) => {
        return permissions.some(perm => {
          if (perm.code === permission) {
            return true;
          }
          if (perm.children && Array.isArray(perm.children)) {
            return hasPermissionRecursive(perm.children);
          }
          return false;
        });
      };

      return hasPermissionRecursive(this.userPermissions);
    }
    ,

    navigateToQuickDiagnosis(path) {
      this.$router.push(path);
    }
    ,
    handleSelect(index) {
      this.activeIndex = index;
    }
    ,
    handleOpen(index) {
      // 当submenu打开时，添加到openeds数组中
      if (!this.openeds.includes(index)) {
        this.openeds.push(index);
      }
      this.updateLocalStorage();
    }
    ,
    handleClose(index) {
      // 当submenu关闭时，从openeds数组中移除
      const i = this.openeds.indexOf(index);
      if (i > -1) {
        this.openeds.splice(i, 1);
      }
      this.updateLocalStorage();
    },
    updateLocalStorage() {
      try {
        localStorage.setItem("openeds", JSON.stringify(this.openeds));
      } catch (error) {
        console.error('Error saving to localStorage:', error);
        // 可以考虑提供替代方案，比如暂时不存储或者存储到 sessionStorage
        try {
          sessionStorage.setItem("openeds", JSON.stringify(this.openeds));
        } catch (sessionError) {
          console.error('Error saving to sessionStorage:', sessionError);
        }
      }
    },
    navigateTo(route) {
      if (this.isAnalyzing || this.isTesting) {

        const warningMessage = this.isAnalyzing ? this.$t('ProductDiagnosis.drawingInterruptionWarning') : this.$t('ProductDiagnosis.testInProgressWarning');

        // 如果正在绘图，显示确认对话框
        this.$confirm(warningMessage, this.$t('PluginBox.title'), {
          confirmButtonText: this.$t('settingPage.confirm'),
          cancelButtonText: this.$t('settingPage.cancel'),
          type: 'warning',
        }).then(() => {
          // 用户确认离开
          if (this.isAnalyzing) {
            EventBus.emit('request-stop-analysis', true);
          } else if (this.isTesting) {
            EventBus.emit('request-stop-test', true);
          }
          //执行导航
          this.$router.push(route);
          this.activeIndex = route;
        }).catch(() => {
          // 用户取消离开，不执行任何操作
          if (this.isAnalyzing) {
            EventBus.emit('request-stop-analysis', false);
          } else if (this.isTesting) {
            EventBus.emit('request-stop-test', false);
          }
        });
      } else {
        // 如果不在绘图，直接执行导航
        this.$router.push(route);
        this.activeIndex = route;
      }
    }
    ,
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path; // 当路由变化时，更新activeIndex
    },
  },
};
</script>

<style scoped>

.left-align {
  text-align: left;
  display: block;
}

.menu-item-multiline {
  white-space: normal !important;
  /* 允许文本自动换行 */
  line-height: 20px !important;
  /* 行高设置为 20px */
}

/* 全局样式或组件局部样式中 */
.el-menu-item.active .svg-icon {
  color: white;
  /* 当元素具有 .active 类时，SVG 图标的颜色变为白色 */
}

.svg-icon {
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 10px;
  color: #000000;
}

.custom-submenu:hover {
}

/* 鼠标悬停时仅改变父菜单项文字和图标颜色 */
:deep(.el-submenu__title:not(.no-hover):hover) {
  background-color: inherit !important;
  /* 继承背景色 */
  color: #409EFF !important;
  /* 您希望的悬停文字颜色 */
}

:deep(.el-submenu__title:not(.no-hover):hover .el-icon) {
  background-color: inherit !important;
  /* 继承背景色 */
  color: rgb(0, 154, 68) !important;
  /* 设置文字为绿色 */
  /* 您希望的悬停图标颜色 */
}

:deep(.el-menu) {
  background-color: rgb(245, 245, 245);
}

/* 对所有顶级菜单项应用统一的左内边距 */
.el-menu-vertical-demo > .el-menu > .el-menu-item,
.el-menu-vertical-demo > .el-menu > .el-submenu > .el-submenu__title {
  padding-left: 17px !important;
}

/* 对所有子菜单项应用与其直接父菜单相同的左内边距，以保持对齐 */
.el-menu-vertical-demo .el-submenu .el-menu-item {
  padding-left: 17px !important;
}

/* 在每个模块项之后添加间隔 */
.el-menu-item,
.el-submenu__title {
  margin-bottom: 5px;
  /* 调整间隔的大小 */
}

.el-menu {
  width: 100%;
  border-right: none;
  height: 100%;
}

.el-menu-item,
.el-submenu__title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.el-icon {
  font-size: 20px;
  margin-right: 10px;
}

.el-submenu__icon-arrow {
  right: 10px;
}

.el-submenu__title {
  justify-content: space-between;
  transition: background-color 0.3s;
}

.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  right: 10px;
}

.el-menu--collapse .el-menu-item {
  justify-content: center;
}

.el-menu--collapse .el-icon {
  font-size: 16px;
  margin-right: 0;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 209px !important;
  min-height: 400px;
}

.el-menu-vertical-demo.el-menu--collapse {
  width: 60px !important;
}


/* 如果您的菜单有折叠状态，可能需要为折叠状态下的菜单设置不同的宽度 */
.el-menu--collapse {
  width: 60px !important;
  /* 适当的宽度，例如 60px */;
}


/* 新增样式用于折叠状态 */
.collapsed {
  width: 60px;
}

.active {
  background-color: rgb(160, 160, 160);
  color: white;
}

.menu-container {
  display: flex;
  align-items: center;
}


/* 响应式样式 */
@media (max-width: 768px) {
  .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    right: 10px;
  }

  .el-menu--collapse .el-icon {
    font-size: 16px;
    margin-right: 0;
  }

  .menu-container {
    flex-direction: column;
    /* 在小屏幕上堆叠内容 */
  }
}

/* 添加一个自定义类来设置产品诊断菜单的颜色 */
.el-menu-item.not-selected {
  color: black;
  /* 设置文字颜色为白色 */
}

/* 确保选中状态且 no-hover 类存在时背景色不改变 */
.no-hover,
.no-hover:hover {
  background-color: rgb(160, 160, 160) !important;
  color: white !important;
}

.el-sub-menu:hover > .el-submenu__title {
  color: rgb(0, 154, 68) !important;
}

.el-sub-menu:hover > .el-submenu__title .el-icon {
  color: rgb(0, 154, 68) !important;
}

/* 仅在未选中菜单项上修改鼠标悬停样式 */
.el-menu-item:not(.is-active):not(.no-hover):hover,
.el-submenu__title:not(.is-active):not(.no-hover):hover {
  background-color: rgb(195, 217, 201) !important;
  color: rgb(0, 154, 68) !important;
}

/* 针对具有子模块的父模块的悬停样式 */
.el-sub-menu:hover .el-submenu__title,
.el-sub-menu:hover .el-icon {
  color: rgb(0, 154, 68) !important;
  /* 绿色文字和图标 */
}

/* 保持子菜单项在悬停时的样式不变 */
.el-sub-menu .el-menu-item:hover {
  background-color: inherit;
  /* 保持原有背景色 */
  color: inherit;
  /* 保持原有文字颜色 */
}

/* 设置菜单项和子菜单标题的高度 */
.el-menu-item,
.el-submenu__title {
  height: 40px;
  /* 设置高度为 40px */
  line-height: 40px;
  /* 设置行高与高度相同，确保文本垂直居中 */
}

/* 针对折叠状态下的菜单样式（如果需要） */
.el-menu--collapse .el-menu-item,
.el-menu--collapse .el-submenu__title {
  height: 40px;
  /* 设置折叠状态下的高度为 40px */
  line-height: 40px;
  /* 设置行高与高度相同，确保文本垂直居中 */
}

/* 设置整个菜单的宽度 */
.el-menu {
  width: 209px;
  /* 设置宽度为 209px */
}

/* 设置菜单项和子菜单标题的宽度 */
.el-menu-item,
.el-submenu__title {
  width: 209px;
  /* 设置宽度为 209px */
}
</style>
