<template>
  <el-dialog
      v-model="dialogVisible"
      width="600"
  >
    <div class="warning-message">
      <el-icon size="22" style="color: rgb(250,173,20)"><Warning /></el-icon>&nbsp;&nbsp;&nbsp;
      <span class="warning-text">{{ message }}</span>
    </div>

    <div class="dialog-footer">
      <el-button @click="cancel" class="custom-reset-button">{{ $t('userManage.cancel') }}</el-button>
      <el-button @click="confirm" class="button-background-color" :loading="isButtonLoading">{{
          $t('userManage.confirm')
        }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import {Warning} from "@element-plus/icons-vue";

export default {
  components: {Warning},
  mixins: [dialogMixin],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    message: String,
  },
  data() {
    return {
      dialogVisible: this.visible,
    };
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
  methods: {
    async confirm() {
      await this.handleButtonAction(async () => {
        this.$emit('confirm');
        this.close();
      });
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
  }
}
</script>

<style scoped>
</style>