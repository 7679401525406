<script>
export default {
  name: "PauseIcon"
}
</script>

<template>

  <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" version="1.1"
       xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path
        d="M 242.98667 145.06667 L 409.60000 311.70133 L 322.34667 398.99733 L 155.69067 232.34133 C 150.64509 227.29626 143.41137 225.10766 136.41526 226.50945 C 129.41915 227.91124 123.58704 232.71782 120.87467 239.31733 L 109.56800 266.79467 C 62.17068 382.08000 88.72660 514.55668 176.896 602.66667 L 181.88800 607.53067 C 261.26716 683.46909 375.01281 711.51964 480.59733 681.19467 L 486.40000 679.44533 L 686.272 879.29600 C 713.27695 906.31923 749.91105 921.50757 788.11474 921.51958 C 826.31844 921.53158 862.96207 906.36625 889.98400 879.36000 L 893.41867 875.79733 C 946.37546 819.03767 944.82819 730.52215 889.92 675.648 L 690.04800 475.79733 L 691.81867 469.952 C 722.83004 361.97711 692.75668 245.67876 613.29067 166.272 C 525.18463 78.11158 392.71938 51.55627 277.44 98.94400 L 249.96267 110.25067 C 243.36315 112.96304 238.55658 118.79515 237.15478 125.79126 C 235.75299 132.78737 237.94159 140.02109 242.98667 145.06667 Z  M 482.34667 268.096 L 373.33333 159.08267 C 440.64271 152.65533 507.30375 176.63610 555.09333 224.46933 C 619.75223 289.08561 639.23106 386.23579 604.48000 470.78400 L 594.04800 496.128 L 831.72267 733.824 C 848.08228 749.25029 854.76131 772.35111 849.15792 794.12746 C 843.55453 815.90381 826.55491 832.91174 804.78131 838.52577 C 783.00770 844.13980 759.90362 837.47207 744.46933 821.12000 L 506.75200 583.424 L 481.38667 593.85600 C 396.83251 628.61664 299.67090 609.13720 235.05067 544.46933 L 230.67733 539.98933 C 185.70209 492.48378 163.46341 427.85238 169.68533 362.73067 L 278.69867 471.74400 C 300.84603 493.89146 336.06833 495.94556 360.64 476.52267 L 362.47467 474.98667 L 364.26667 473.38667 L 365.99467 471.76533 L 482.36800 355.39200 C 504.50248 333.23345 506.53820 298.01178 487.10400 273.45067 L 485.568 271.616 L 483.968 269.824 L 482.34667 268.096 Z">
    </path>
  </svg>

</template>

<style scoped>

</style>