<template>

  <div class="right-container">
    <el-pagination :current-page="currentPage" :page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :small="small"
                   :disabled="disabled" :background="true" layout="total, sizes, prev, pager, next ,jumper"
                   :total="totalRecords"
                   @size-change="handleSizeChange" @current-change="handleCurrentPageChange"
                   style="margin-left: 20px;margin-top: 20px; margin-bottom: 20px;"/>
  </div>

</template>

<script>
export default {
  props: {
    totalRecords: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    small: Boolean, // 定义 small 属性为布尔类型
    disabled: Boolean, // 定义 disabled 属性为布尔类型
    background: Boolean, // 定义 background 属性为布尔类型
  },
  methods: {
    handleSizeChange(newPageSize) {
      this.$emit('page-size-change', newPageSize);
    },
    handleCurrentPageChange(newPage) {
      this.$emit('page-change', newPage);
    },
  },
};
</script>
<style>
.right-container {
  display: flex;
  justify-content: flex-end;
}

.el-pagination .el-pager .number:hover {
  color: black!important;

}
</style>
