import router from "@/router";
import axios from "axios";

/**
 * 和后端同事对接的接口
 * */

const baseApiUrl = process.env.VUE_APP_API_URL;

// 创建一个 Axios 实例
const axiosInstance = axios.create({
    baseURL: baseApiUrl, // 您的 API 基础URL
});

export function listToTreeToDefectCategoryOptions(list, parent_id = 0) {
    let tree = [];

    for (let i = 0; i < list.length; i++) {
        if (list[i].parent_category_id === parent_id) {
            let obj = list[i];
            if (list[i] && list[i].category_id) {
                let temp = listToTreeToDefectCategoryOptions(list, list[i].category_id);
                if (temp.length > 0) {
                    obj.children = temp;
                }
                tree.push(obj);
            }
        }
    }

    return tree;
}

export function listToTree(list, parent_id = 0) {
    let tree = [];
    let temp;

    for (let i = 0; i < list.length; i++) {
        if (list[i].pid === parent_id) {
            let obj = list[i];
            temp = listToTree(list, list[i].id);
            if (temp.length > 0) {
                obj.children = temp;
            }
            tree.push(obj);
        }
    }

    return tree;
}

// 添加响应拦截器
axiosInstance.interceptors.response.use(
    response => {

        // 确保检查的是数字而非字符串（根据实际情况调整）
        if (response.data && response.data.code === -100) {
            localStorage.removeItem('token');
            router.push('/LoginPage'); // 确保这是正确的登录页面路由
            // 可能还需要返回一个错误或抛出一个异常
            return Promise.reject(new Error('Session expired, please login again.'));
        }
        return response;
    },
    error => {
        // 检查错误响应，并检查是否是会话失效
        if (error.response && error.response.data.code === -100) {
            // 如果是401错误，清除本地存储的token并重定向到登录页面
            localStorage.removeItem('token');
            router.push('/LoginPage'); // 假设 '/login' 是您的登录页面路由
        }
        // 返回Promise拒绝
        return Promise.reject(error);
    }
);

//用户登录
export async function loginUser(username, password) {
    const response = await axiosInstance.post(`${baseApiUrl}/api/login`, {
        email: username,
        password: password,
    });

    return response.data;
}

// 获取用户权限的函数
export async function getUserPermissions() {
    // 获取存储在localStorage中的token
    const token = localStorage.getItem('token');

    // 确保token存在
    if (!token) {
        throw new Error('No token found');
    }
    // 发送GET请求到您的API服务器
    const response = await axiosInstance.get(`${baseApiUrl}/api/info`, {
        headers: {
            // 假设您使用Bearer token进行认证
            'Authorization': `Bearer ${token}`,  // 使用Bearer令牌
            'Content-Type': 'multipart/form-data',
        }
    });
    return response.data;
}

//退出登录
export async function logoutUser() {
    // 创建一个 FormData 实例，如果需要发送数据
    const formData = new FormData();

    // 设置请求头
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    const response = await axiosInstance.post(`${baseApiUrl}/api/logout`, formData, config);

    return response.data;
}

// 退出登录
/*export async function logoutUser() {
    try {
        // 创建一个 FormData 实例，如果需要发送数据
        const formData = new FormData();

        // 设置请求头
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const response = await axiosInstance.post(`${baseApiUrl}/api/logout`, formData, config);

        return response.data;
    } catch (error) {
        // 检查错误信息是否包含会话过期
        if (error.message.includes('Session expired')) {
            // 忽略会话过期错误
            return;
        }
        // 重新抛出其他错误
        throw error;
    }
}*/

/**
 * 重置密码接口
 *
 * @param userId
 * @param newPassword
 * @returns {Promise<any>}
 */
export async function resetPassword(userId, newPassword) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    // 构建 application/x-www-form-urlencoded 类型的请求体
    const params = new URLSearchParams();
    params.append('password', newPassword);

    // 发送 POST 请求到指定的 API 路径
    const response = await axiosInstance.post(`/api/users/${userId}`, params, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    });

    // 返回响应数据
    return response.data;
}

/**
 * 展示用户列表接口
 *
 * @returns {Promise<any>}
 */
export async function getUsers() {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.get(`${baseApiUrl}/api/users`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data; // 假设响应数据直接在 data 属性中
}


export async function getUsersData(page, per_page, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/users?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function getRoleUsersData(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/user-role-index?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data; // 假设响应数据直接在 data 属性中
}


/**
 * 新增账号接口
 *
 * @param accountData
 * @returns {Promise<any>}
 */
export async function addAccount(accountData) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/users`, accountData, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'   // 设置内容类型为 JSON
        }
    });
    console.log(12383736666)
    console.log(response.data)
    console.log(12383736666)
    return response.data;
}

/**
 * 删除账号接口
 *
 * @param accountId
 * @returns {Promise<any>}
 */
export async function deleteAccount(accountId) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/users/${accountId}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });

    return response.data;
}

// 定义批量删除账号的接口函数
export async function batchDeleteAccounts(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/users/batch-delete`, data, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });

    return response.data;
}

export async function batchUpdateAccount(data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/users/batch-update-role`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}


// 获取账号详情
export async function getAccountDetails(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/users/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

/**
 * 编辑账号接口
 *
 * @param id
 * @param data
 * @returns {Promise<any>}
 */
export async function updateAccount(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/users/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function updateLang(data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/auth/update-lang`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function showAccount(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/users/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function updatePassword(data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/users/change-password`, {
        id: data.id,
        password: data.password
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

//新增角色接口
export async function apiCreateRole(roleData) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/roles`, roleData, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

/**
 * 下载接口
 * @param htmlContent
 * @returns {Promise<any>}
 */

export async function convertHtmlToPdf(htmlContent) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/common/html-to-pdf`, {content: htmlContent}, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    return response.data;
}

export async function sendFeedback(feedbackData) {
    const token = localStorage.getItem('token'); // 获取存储在localStorage中的token
    const response = await axiosInstance.post(
        `${baseApiUrl}/api/customer-feedback`, // API端点URL
        feedbackData, // 反馈数据
        {
            headers: {
                'Authorization': `Bearer ${token}`, // 在请求头中携带token
                'Content-Type': 'application/json' // 设置请求的内容类型为JSON
            }
        }
    );
    return response.data; // 返回从服务器接收到的响应数据
}


//编辑角色的菜单和权限
export async function saveRoleMenusAndPermission(roleData) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/role/menu-permission`, roleData, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

export async function getMenuList() {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.get(`${baseApiUrl}/api/menu/list`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}


/**
 * 删除角色接口
 *
 * @param roleId
 * @returns {Promise<any>}
 */
export async function deleteRole(roleId) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/roles/${roleId}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

/**
 * 展示角色列表接口
 *
 * @returns {Promise<any>}
 */
export async function getRoles() {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.get(`${baseApiUrl}/api/roles`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function getRolesOptions() {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.get(`${baseApiUrl}/api/role-simple`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

/**
 * 编辑角色接口
 *
 * @param id
 * @param data
 * @returns {Promise<any>}
 */
export async function updateRole(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/roles/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function getPermissionsByRole(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/roles/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

/**
 * 关于权限的接口
 */
export async function addPermission(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/roles/3/permission`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

export async function deletePermission(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/roles/3/permission/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}


/**
 * 关于菜单的接口
 */
export async function createMenu(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/roles/3/menu`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

export async function deleteMenu(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/roles/3/menu/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

/**
 * 申请售后模块的全部数据展示
 */
export async function getAfterSalesData(page, per_page) {
    const response = await axiosInstance.get(`${baseApiUrl}/api/after-sales`, {
        params: {
            page: page,
            per_page: per_page,
        },
    });

    return response.data;
}

/**
 * 申请售后的删除数据接口
 *
 * @param afterSalesId
 * @returns {Promise<any>}
 */
export async function deleteAfterSalesData(afterSalesId) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/after-sales/${afterSalesId}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

export async function addAfterSalesData(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/after-sales`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });

    return response.data;
}

export async function showAfterSalesData(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/after-sales/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function updateAfterSalesData(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/after-sales/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function updateAfterSalesApply(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/after-sale-apply/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function updateAfterSalesApplyIds(data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/after-sale-batch-apply`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}


export async function getExportData(queryParameters) {
    const token = localStorage.getItem('token'); // 获取Authorization token
    const params = new URLSearchParams({
        ...queryParameters    // 展开其他查询参数
    }).toString();
    const response = await axiosInstance.get(`${baseApiUrl}/api/after-sales-export?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}` // 将token添加到请求头中
        },
    });

    return response.data;
}


// 获取国家列表的函数
export async function getCountries(page = 1, per_page = 100) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/countries?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'
        }
    });
    // 返回从后端获取的国家列表数据
    return response.data;
}

//获取产品升级的固件升级下拉框数据和参数升级下拉框数据
export async function getFirmwareAndParameterData(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    // 发送GET请求到后端的countries端点，获取国家列表数据
    const response = await axiosInstance.get(`${baseApiUrl}/api/firmware-list`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        },
        params: data // 使用params属性来传递查询参数
    });
    // 返回从后端获取的国家列表数据
    return response.data;
}

/**
 * 获取所有升级记录数据及搜索数据
 *
 * @param page
 * @param per_page
 * @param query
 * @returns {Promise<any>}
 */
export async function getUpgradeRecords(page = 1, per_page, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/upgrade-records?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

/**
 关于组织管理的接口
 */
export async function getOrganizationsData(page = 1, per_page = 1000, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    // 在 query 对象中添加排序参数
    query["id-asc"] = 1;

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/organizations?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

//获取二级组织数据
export async function getTwoLevelOrganizations(level) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        ...level    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/organization-simple?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        },
    });

    return response.data; // 假设响应数据直接在 data 属性中
}

//获取顶级组织和二级组织
export async function getOneAndTwoLevelOrganizations(level) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        ...level    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/organization-simple?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        },
    });

    return response.data; // 假设响应数据直接在 data 属性中
}

export async function getRoleData() {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const response = await axiosInstance.get(`${baseApiUrl}/api/role-simple`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        },
    });

    return response.data; // 假设响应数据直接在 data 属性中
}


export async function deleteOrganizations(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const response = await axiosInstance.delete(`${baseApiUrl}/api/organizations/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });

    return response.data;
}

export async function addOrganizations(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/organizations`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });

    return response.data;
}


export async function updateOrganizations(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/organizations/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

/**
 关于诊断报告的接口
 */

export async function getCustomerFeedback(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/customer-feedback?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            //'Content-Type': 'multipart/form-data'
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function getDiagnoseReport(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/diagnoses?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            //'Content-Type': 'multipart/form-data'
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

/**
 关于自注册账号管理的接口
 */
export async function getSelfRegister(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/self-register?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });

    return response.data; // 假设响应数据直接在 data 属性中
}

export async function addSelfRegister(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/self-register`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

export async function getAfterSales(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/after-sales?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function getDefectCategorysData() {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.get(`${baseApiUrl}/api/after-sale-detail-error-type`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}


/**
 * 申请售后及售后处理的详情接口
 */

export async function getAfterSalesDetails(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();


    const response = await axiosInstance.get(`${baseApiUrl}/api/after-sale-details?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function addAfterSalesDetails(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/after-sale-details`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

/**
 * 删除角色接口
 *
 * @param id
 * @returns {Promise<any>}
 */
export async function deleteAfterSalesDetails(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/after-sale-details/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

export async function updateAfterSalesDetails(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/after-sale-details/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function showAfterSalesDetails(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/after-sale-details/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

/**
 关于固件的接口
 */

export async function getFirmware(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();
    const response = await axiosInstance.get(`${baseApiUrl}/api/firmware?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function updateFirmware(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/firmware/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function showFirmware(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/firmware/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function deleteFirmware(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/firmware/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

export async function addFirmware(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/firmware`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

/**
 关于快捷诊断的接口
 */
export async function getFaqs(page, per_page, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        ...page !== undefined && {page},
        ...per_page !== undefined && {per_page},
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/faqs?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function getFaqList(page, per_page, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        ...page !== undefined && {page},
        ...per_page !== undefined && {per_page},
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/faq-list?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function getErrorName(errCode) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    // 构建查询参数
    const params = new URLSearchParams({
        "code-find": errCode  // 使用传递进来的 errCode 作为查询参数
    }).toString();

    // 发起 GET 请求
    const response = await axiosInstance.get(`${baseApiUrl}/api/test-items?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    // 返回响应数据
    return response.data;
}

export async function getSystemCheckData(type) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    // 构建查询参数
    const params = new URLSearchParams({
        "product_type-eq": type,
        "type-eq": 1
    }).toString();

    // 发起 GET 请求
    const response = await axiosInstance.get(`${baseApiUrl}/api/test-items?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    // 返回响应数据
    return response.data;
}

export async function getDynamicManualData(page = 1, per_page = 100) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    // 构建查询参数
    const params = new URLSearchParams({
        "type-eq": 0,  // 使用传递进来的 errCode 作为查询参数
        "page": page,
        "per_page": per_page
    }).toString();

    // 发起 GET 请求
    const response = await axiosInstance.get(`${baseApiUrl}/api/test-items?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    // 返回响应数据
    return response.data;
}

export async function getDynamicTestData(itemnames) {
    const token = localStorage.getItem('token');
    const organization = localStorage.getItem('organizationId');

    // 组织查询参数
    const params = new URLSearchParams({
        name: itemnames.join(','), // 将所有 itemnames 组合为一个逗号分隔的字符串
        organizationId: organization, // 动态组织ID
        type: '0' // 假设类型为0，如果需要动态设置，可以调整
    }).toString();

    // 发起 GET 请求
    const response = await axiosInstance.get(`${baseApiUrl}/api/test-items-list?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });

    return response.data;
}

export async function getTestItemById(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/test-items/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function getFilteredTestItems(queryParameters) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/test-items`, {
        params: queryParameters,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function saveTestItemDocuments(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/test-items-documents`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

export async function postDiagnoses(data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/diagnoses`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    return response.data;
}


export async function updateFaqs(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/faqs/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function deleteFaqs(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/faqs/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

export async function addFaqs(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/faqs`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

/**
 关于快捷诊断左侧目录的接口
 */
export async function getFaqCategories(page = 1, per_page = 1000, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    // 在 query 对象中添加排序参数
    query["id-asc"] = 1;

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();
    const response = await axiosInstance.get(`${baseApiUrl}/api/faq-categories?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function showFaqsCategories(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/faq-categories/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function updateFaqCategories(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/faq-categories/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function deleteFaqCategories(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const response = await axiosInstance.delete(`${baseApiUrl}/api/faq-categories/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });

    // 检查状态码，确保操作成功
    if (response.status === 200 || response.status === 204) {
        return response.data; // 操作成功
    } else {
        throw new Error(`Deletion failed with status: ${response.status}`);
    }
}


export async function addFaqCategories(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/faq-categories`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

/**
 关于快捷诊断的编辑内容的接口
 */

export async function getFaqContents(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/faq-contents?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function showFaqContents(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/faq-contents/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function showFaqData(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/faqs/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function updateFaqContents(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/faq-contents/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function deleteFaqContents(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/faq-contents/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

export async function addFaqContents(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/faq-contents`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

/**
 关于产品管理的型号管理的接口
 */

export async function getProductModels(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/product-models?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function showProductModels(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/product-models/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function updateProductModels(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/product-models/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}

export async function deleteProductModels(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/product-models/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

export async function addProductModels(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/product-models`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

/**
 * 上传接口
 */

export async function uploadFileData(formData) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const response = await axiosInstance.post(`${baseApiUrl}/api/common/upload`, formData, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
}


/**
 * 适配型号下拉框接口
 */
export async function getProductModelData(type, version_type = null) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        "type-eq": type,  // 如果这个是固定的，可以直接这么写
        "version_type-eq": version_type  // 添加新的查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/product-model-simple?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

/**
 * 故障大类，故障小类，errorcode列表
 * @param params
 * @returns {Promise<any>}
 */
export async function getErrorCategories(params) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const queryParams = new URLSearchParams();

    // 添加参数到查询字符串
    if (params.name) {
        queryParams.append("name-eq", params.name);
    } else if (params.pid) {
        queryParams.append("pid-eq", params.pid);
    }

    const response = await axiosInstance.get(`${baseApiUrl}/api/faq-categories-simple?${queryParams}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

//添加阈值数据
export async function saveThresholdData(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/test-item-threshold-batch`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

export async function getTestItemThresholds(id, organization = '') {
    const token = localStorage.getItem('token');

    const params = new URLSearchParams({
        "test_item_id-eq": id
    });

    // 仅当 organization 有值时才添加到查询参数
    if (organization) {
        params.append("organization_ids", organization);
    }

    const response = await axiosInstance.get(`${baseApiUrl}/api/test-item-thresholds?${params.toString()}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

// 上传 Excel 文件
export async function uploadExcelFile(formData) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post('/api/after-sale-detail-import', formData, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
}

export async function loadImportData(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/job-detail?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function jobDetailError(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/job-detail-error?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function changePassword(data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`${baseApiUrl}/api/auth/update-password`, {
        current_password: data.current_password,
        new_password: data.new_password
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

//配置管理的故障代码新增接口
export async function addFaultCode(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/fault-code`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

//配置管理的故障代码查询接口
export async function getFaultCodeList(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/fault-code?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

//配置管理的故障代码删除接口
export async function deleteFaultCode(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/fault-code/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

//配置管理的故障类型新增接口
export async function addFaultType(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/fault-type`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

export async function getFaultTypeList(page = 1, per_page = 10, query = {}) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌

    const params = new URLSearchParams({
        page,       // 分页页码
        per_page,   // 每页记录数
        ...query    // 展开其他查询参数
    }).toString();

    const response = await axiosInstance.get(`${baseApiUrl}/api/fault-type?${params}`, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
        }
    });
    return response.data; // 假设响应数据直接在 data 属性中
}

export async function deleteFaultType(id) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.delete(`${baseApiUrl}/api/fault-type/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // 使用 Bearer 令牌
        }
    });
    return response.data;
}

export async function getFaultCode(id) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/fault-code/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function updateFaultCode(id, data) {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`${baseApiUrl}/api/fault-code/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

//产品诊断弹框的故障大类显示列表
export async function getErrorTypeFirst(params) {
    const token = localStorage.getItem('token');
    const queryParams = new URLSearchParams();

    if (params.lang) {
        queryParams.append("lang", params.lang);
    }
    queryParams.append("motor_type", params.motor_type);

    const response = await axiosInstance.get(`${baseApiUrl}/api/fault-type-first?${queryParams}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function getErrorTypeSecond(params) {
    const token = localStorage.getItem('token');
    const queryParams = new URLSearchParams();

    if (params.lang) {
        queryParams.append("lang", params.lang);
    }
    if (params.firstName) {
        queryParams.append("firstName", params.firstName);
    }


    const response = await axiosInstance.get(`${baseApiUrl}/api/fault-type-second?${queryParams}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function getErrorTypeSimple(params) {
    const token = localStorage.getItem('token');
    // 直接将 params 转换为 URLSearchParams
    const queryParams = new URLSearchParams(params);

    const response = await axiosInstance.get(`${baseApiUrl}/api/fault-type-simple?${queryParams}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function getFaultCodeSimple() {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`${baseApiUrl}/api/fault-code-simple`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data;
}

export async function addConnectRecord(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`${baseApiUrl}/api/connect-record`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

// 发送邮件接口
export async function sendMail(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`/api/send-mail`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}

// 重置密码接口
export async function resetPasswordToForget(data) {
    const token = localStorage.getItem('token'); // 从 localStorage 获取令牌
    const response = await axiosInstance.post(`/api/reset-password`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,  // 使用 Bearer 令牌
            'Content-Type': 'application/json'   // 设置内容类型为 JSON
        }
    });
    return response.data;
}