<template>
  <div id="app" v-loading="loading" element-loading-text="Loading...">
    <header v-if="!hideLayout">
      <NavigationBar/>
    </header>
    <main class="main-container" >
      <section id="sidebar" v-if="!hideLayout">
        <ModuleSidebar/>
      </section>
      <section id="content" :class="{ 'with-margins': !hideLayout }">
        <el-config-provider :locale="locale">
          <router-view/>
        </el-config-provider>
      </section>
    </main>
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import ModuleSidebar from '@/components/ModuleSidebar.vue';
import {onMounted, ref, watch, computed} from 'vue';
import {useRoute} from 'vue-router';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import fr from 'element-plus/dist/locale/fr.mjs';
import it from 'element-plus/dist/locale/it.mjs';
import ja from 'element-plus/dist/locale/ja.mjs';
import hu from 'element-plus/dist/locale/hu.mjs';
import pl from 'element-plus/dist/locale/pl.mjs';
import sv from 'element-plus/dist/locale/sv.mjs';
import de from 'element-plus/dist/locale/de.mjs';
import nl from 'element-plus/dist/locale/nl.mjs';
import store from "@/store";

export default {
  name: 'App',
  components: {
    NavigationBar,
    ModuleSidebar,
  },
  setup() {
    const route = useRoute();
    const hideLayout = ref(false);
    const loading = ref(true);

    const locales = {zh: zhCn, en, fr, it, ja, hu, pl, sv, de, nl};
    const locale = computed(() => locales[store.getters.currentLanguage] || zhCn);

    const setLoading = (value) => {
      loading.value = value;
      if (!value) {
        const loadingContainer = document.querySelector('.loading-container');
        if (loadingContainer) {
          loadingContainer.style.display = 'none';
        }
      }
    };

    watch(route, (currentRoute) => {
      hideLayout.value = currentRoute.meta.hideLayout || false;
    });

    onMounted(() => {
      setLoading(false);
      document.body.style.setProperty('--el-color-primary', 'rgb(0,154,68)');
      document.body.style.setProperty('--el-color-primary-hover', '#c3d9c9');
      document.body.style.setProperty('--el-color-primary-light-9', '#f0f9eb');
      document.body.style.setProperty('--el-color-primary-light-3', '#95d475');
      document.body.style.setProperty('--el-color-primary-light-5', '#95d475');
    });

    return {hideLayout, loading, locale};
  }
};
</script>

<style scoped>

body {
  height: 100%;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.main-container {
  display: flex;
  height: 100%;
}

#sidebar {
  position: fixed;
  left: 0;
  top: 48px;
  width: 210px;
  height: calc(100% - 48px); /* 全视口高度 */
  background-color: rgb(245, 245, 245);
  z-index: 888;
}

/* 当不是登录页时应用的样式 */
#content.with-margins {
  margin-top: 48px;
  margin-left: 210px;
}

#content {
  flex-grow: 1;
}

/* 如果 header 存在并且需要留出空间，确保它不会与侧边栏重叠 */
header {
  height: 48px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* 设置合适的 height、padding 等 */
  z-index: 999; /* 确保 header 在侧边栏之上 */
  background-color: white; /* 确保 header 有背景色，避免内容重叠时可见 */
}

</style>
