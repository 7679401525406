<script>
export default {
  name: "PauseIcon"
}
</script>

<template>
  <svg viewBox="64 64 896 896" focusable="false" data-icon="pause" width="1em" height="1em" fill="currentColor"
       aria-hidden="true">
    <path
        d="M304 176h80v672h-80zm408 0h-64c-4.4 0-8 3.6-8 8v656c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V184c0-4.4-3.6-8-8-8z"></path>
  </svg>
</template>

<style scoped>

</style>