<template>
    <el-dialog :model-value="visiable" width="500" :title="$t('PluginBox.title')" style="text-align: left" :show-close="false"
               @open="disableScroll" @close="enableScroll"
    >

        <div>
              <p v-if="pluginStatus === 1">
                {{ $t('PluginBox.installDescription') }}
              </p>
              <p v-else-if="pluginStatus === 2">
                {{ $t('PluginBox.upgradeDescription') }}
              </p>
        </div>

        <div>
            <a :href="pluginUrl" style="color: var(--el-color-primary);"> {{ $t('PluginBox.downloadDriver') }}</a>
        </div>

        <template #footer>
            <div>
                <el-button :onclick="onClick" class="button-background-color"> {{ $t('PluginBox.confirmButton') }}</el-button>
            </div>
        </template>

    </el-dialog>
</template>


<script setup>

import {defineProps, onMounted, onUnmounted} from 'vue'
import EventBus from "@/eventBus";
import dialogMixin from "@/mixins/dialogMixin";

const props = defineProps({
    visiable: {
        type: Boolean,
        default: false
    },
    pluginStatus: {
      type: Number,
      default: 0,
    },
    pluginUrl: {
        type: String,
        default: '',
    },
    handleClose: {
        type: Function,
        required: true
    }
})

function onClick() {
    props.handleClose();
}

onMounted(() => {
  EventBus.on('show-plugin-box', () => {
  });
});

onUnmounted(() => {
  EventBus.off('show-plugin-box');
});

// 将 mixin 的方法定义到组件中
const { disableScroll, enableScroll } = dialogMixin.methods;
</script>